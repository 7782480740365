body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

@font-face {
    font-family: 'EuclidCircularB';
    src: local('Euclid Circular B Light.ttf'),
    url("/src/assets/fonts/euclid-b/Euclid Circular B Light.ttf") format("truetype");
    font-display: swap;
    font-weight: normal;
    font-style: normal;
}

